document.addEventListener("turbolinks:load", function() {
  $input = $("input[data-behavior='autocomplete']")

  if ($input.length) {
    let categories = []

    Object.keys($input.data('categories')).forEach(function (key) {
      var category = $input.data('categories')[key]
      categories.push({
        listLocation: key,
        header: `<strong>${category}</strong>`
      })
    })

    var options = {
      getValue: "title",
      url: function(phrase) {
        return $input.data('url') + "?query=" + phrase;
      },
      categories: categories,
      list: {
        onChooseEvent: function() {
          var url = $input.getSelectedItemData().url
          $input.val($input.getSelectedItemData())
          Turbolinks.visit(url)
        }
      }
    }

    $input.easyAutocomplete(options)
  }
});
