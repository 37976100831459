// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

// bootsrap
import * as bootstrap from 'bootstrap';

// jquery
import $ from 'jquery';
global.$ = $
global.jQuery = $

// FontAwesome
import "@fortawesome/fontawesome-free/js/all";

// Easy Autocomplete
import 'easy-autocomplete/dist/jquery.easy-autocomplete'

import '../stylesheets/application'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Plugins
require("./plugins")
require("./forms")

document.addEventListener("turbolinks:load", function () {
  $(".modal.show").modal('show');

  $(document).on("change", 'input[type="checkbox"][data-toggle="checkbox"]', function () {
    let toggables = $(`.${this.dataset.target}`)
    if (toggables) {
      if (this.checked) {
        toggables.hide()
      } else {
        toggables.show()
      }
    }
  });

  $(document).on("click", 'a[data-toggle="button"]', function (event) {
    event.preventDefault();

    let toggables = $(`.${this.dataset.target}`)

    if (toggables) {
      toggables.toggleClass("d-none");
    }
  });

  $(".custom-color input").on("change", function () {
    let value = $(this).val();
    let parent = $(this).closest('.custom-color')
    parent.find("input").val(value);
    parent.find(".input-group-text").css("background-color", value);
  });

  $(".custom-color .input-group-text").on("click", function () {
    $(this).siblings('input').trigger('click');
  });

  // Enable tooltips globally
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Enable popovers globally
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

  // Activate Bootstrap scrollspy for the sticky nav component
  const stickyNav = document.body.querySelector('#stickyNav');
  if (stickyNav) {
    new bootstrap.ScrollSpy(document.body, {
      target: '#stickyNav',
      offset: 82,
    });
  }

  // Toggle the side navigation
  const sidebarToggle = document.body.querySelector('#sidebarToggle');
  if (sidebarToggle) {
    // Uncomment Below to persist sidebar toggle between refreshes
    // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
    //    document.body.classList.toggle('sidenav-toggled');
    // }
    sidebarToggle.addEventListener('click', event => {
      event.preventDefault();
      document.body.classList.toggle('sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sidenav-toggled'));
    });
  }

  // Close side navigation when width < LG
  const sidenavContent = document.body.querySelector('#layoutSidenav_content');
  if (sidenavContent) {
    sidenavContent.addEventListener('click', event => {
      const BOOTSTRAP_LG_WIDTH = 992;
      if (window.innerWidth >= 992) {
        return;
      }
      if (document.body.classList.contains("sidenav-toggled")) {
        document.body.classList.toggle("sidenav-toggled");
      }
    });
  }

  // Add active state to sidbar nav links
  let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, '$1');

  if (activatedPath) {
    activatedPath = activatedPath[0];
  } else {
    activatedPath = 'index.html';
  }

  const targetAnchors = document.body.querySelectorAll('[href="' + activatedPath + '"].nav-link');

  targetAnchors.forEach(targetAnchor => {
    let parentNode = targetAnchor.parentNode;
    while (parentNode !== null && parentNode !== document.documentElement) {
      if (parentNode.classList.contains('collapse')) {
        parentNode.classList.add('show');
        const parentNavLink = document.body.querySelector(
          '[data-bs-target="#' + parentNode.id + '"]'
        );
        parentNavLink.classList.remove('collapsed');
        parentNavLink.classList.add('active');
      }
      parentNode = parentNode.parentNode;
    }
    targetAnchor.classList.add('active');
  });

  const toastBasicEl = document.getElementById('toastBasic');
  const toastNoAutohideEl = document.getElementById('toastNoAutohide');

  const toastBasic = new bootstrap.Toast(toastBasicEl);
  const toastNoAutohide = new bootstrap.Toast(toastNoAutohideEl);

  const toastBasicTrigger = document.getElementById('toastBasicTrigger');
  if (toastBasicTrigger) {
    toastBasicTrigger.addEventListener('click', event => {
      toastBasic.show();
    });
  }

  const toastNoAutohideTrigger = document.getElementById('toastNoAutohideTrigger');
  if (toastNoAutohideTrigger) {
    toastNoAutohideTrigger.addEventListener('click', event => {
      toastNoAutohide.show();
    });
  }
})
