const Choices = require('choices.js')

document.addEventListener("turbolinks:load", function () {
  $("#modalContent").on("show.bs.modal", function () {
    if ($("#modalContent .form-select").length) {
      $("#modalContent .form-select").each((_, element) => {
        new Choices(element, {
          removeItems: true,
          removeItemButton: true
        })
      });
    }
  });

  if ($(".form-select").length) {
    $(".form-select").each((_, element) => {
      new Choices(element, {
        removeItems: true,
        removeItemButton: true
      })
    });
  }
})
