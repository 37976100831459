import intlTelInput from 'intl-tel-input';

document.addEventListener('turbolinks:load', function () {
  const telInput = document.querySelector('.tel-input');
  if (telInput) {
    var iti = intlTelInput(telInput, {
      customContainer: 'w-100',
      formatOnDisplay: true,
      geoIpLookup: function (success, failure) {
        $.get('http://ip-api.com/json', function (resp) {
          var countryCode = (resp && resp.countryCode) ? resp.countryCode : 'us';
          success(countryCode);
        });
      },
      initialCountry: 'auto',
      nationalMode: false,
      preferredCountries: ['uy', 'ar'],
      utilsScript: 'https://intl-tel-input.com/node_modules/intl-tel-input/build/js/utils.js'
    });

    telInput.addEventListener('keyup', formatIntlTelInput);
    telInput.addEventListener('change', formatIntlTelInput);

    function formatIntlTelInput() {
      if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
        var currentText = iti.getNumber(intlTelInputUtils.numberFormat.E164);
        if (typeof currentText === 'string') { // sometimes the currentText is an object :)
          iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
        }
      }
    }
  }
})
