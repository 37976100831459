import {DataTable} from "simple-datatables"

window.dataTables = []
const feather = require("feather-icons");

function dataTableOptions(simple) {
  if (!simple) {
    return {}
  } else {
    return {
      "paging": false,
      "searchable": false,
      "footer": false,
      "layout": {
        "top": "",
        "bottom": "",
      }
    }
  }
}

document.addEventListener("turbolinks:before-cache", () => {
  while (window.dataTables.length !== 0) {
    window.dataTables.pop().destroy();
  }
});

document.addEventListener("turbolinks:load", function () {
  if (window.dataTables.length === 0 && $(".data-table").length !== 0) {
    $(".data-table").each((_, element) => {
      const simple = $(element).hasClass('simple');
      const options = dataTableOptions(simple);
      let dataTable = new DataTable(element, options)

      dataTable.on('datatable.init', function() { feather.replace() });
      dataTable.on('datatable.refresh', function() { feather.replace() });
      dataTable.on('datatable.update', function() { feather.replace() });
      dataTable.on('datatable.page', function() { feather.replace() });
      dataTable.on('datatable.sort', function() { feather.replace() });
      dataTable.on('datatable.perPage', function() { feather.replace() });
      dataTable.on('datatable.search', function() { feather.replace() });

      window.dataTables.push(dataTable);
    });
  }
})
