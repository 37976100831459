import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function () {
  $("input[type='file'].autosubmit").on('change', function(event) {
    submitForm(event);
  })

  let timeout;

  $("input[type='text'].autosubmit").on('keyup', function (event) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      submitForm(event);
    }, 500);
  });

  function submitForm(event) {
    var $element = $(event.target)
    var $form = $element.closest('form')
    if ($form.data('remote') == true) {
      Rails.fire($form[0], 'submit')
    } else {
      $form[0].submit()
    }
  }
});
