import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from "@fullcalendar/core/locales/es-us"

function findLocale(locale) {
  if (locale == 'es') {
    return esLocale
  } else {
    return 'es'
  }
}

document.addEventListener("turbolinks:load", function () {
  let calendarEl = document.getElementById('calendar');

  if (calendarEl) {
    let eventsPaths = JSON.parse(calendarEl.dataset.eventsPaths);
    let calendar = new Calendar(calendarEl, {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: calendarEl.dataset.defaultCalendar || 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek'
      },
      selectable: true,
      eventSources: eventsPaths,
      locale: findLocale(calendarEl.dataset.locale),
      select: function (event) {
        let url = calendarEl.dataset.newEventPath;
        let timeZone = calendarEl.dataset.timeZone || 'UTC';
        url += `?start_date=${event.startStr}&end_date=${event.endStr}`
        url += `&start_time_zone=${timeZone}&end_time_zone=${timeZone}`
        $.getScript(url, function() {});
      },
      eventClick: function(info) {
        if (info.event.url) {
          info.jsEvent.preventDefault();
          $.getScript(info.event.url, function () { });
        }
      }
    });

    calendar.render();
    window.calendar = calendar;
  }
})
